#dogs {
  color: white;
  font-family: "Oswald", sans-serif;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  background-color: #383838;
  width: 100%;
  strong {
    font-size: 1.3em;
    font-weight: normal;
  }
  .header {
    position: relative;
    img {
      position: absolute;
      left: -15px;
      top: -10px;
    }
    h1 {
      text-transform: uppercase;
      font-size: 1.9em;
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    width: 100%;
    h2 {
      font-size: 1.3em;
    }
    border-bottom: 2px solid rgba(0,0,0,0.05);
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      width: 200px;
      img {
        margin-left: 10px;
        margin-right: -7px;
      }
      padding: 10px 40px;
      max-width: 50%;
      opacity: .4;
      &:hover {
        cursor: pointer;
      }
      &.active {
        opacity: 1;
        border-bottom: 2px solid #baa095;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #dogs {
    padding: 40px 20px;
    font-size: .9em;
  }
}
