#puppies {
  background-color: #383838;
  width: 100%;
  .content {
    max-width: 1200px;
    position: relative;
    color: white;
    font-family: "Oswald", sans-serif;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    margin: auto;
  }
  button {
    width: fit-content;
    padding: 10px 20px;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    font-size: 0.8em;
    color: white;
    background-color: #baa095;
    margin-top: 10px;
    margin-right: 10px;
    &:hover {
      cursor: pointer;
      opacity: 0.6 !important;
    }
  }
  strong {
    font-size: 1.2em;
    font-weight: normal;
  }
  .header {
    position: relative;
    img {
      position: absolute;
      left: -15px;
      top: -10px;
    }
  }
  .first {
    display: flex;
    align-items: center;
    // width: 1000px;
    width: 100%;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 40px;
    border-radius: 5px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: left;
    button {
      margin-top: 10px;
      margin-right: 10px;
    }
    .btns {
      margin-top: 20px;
      .btn-row {
        display: flex;
        align-items: center;
        button {
          &:last-child {
            width: 130px;
          }
          &:first-child {
            width: 250px;
          }
        }
      }
    }
    img {
      width: 250px;
      object-fit: contain;
      height: auto;
      flex-shrink: 0;
      margin-right: 30px;
      border-radius: 5px;
    }
    .texts {
      p {
        margin: 0;
        text-align: center;
      }
    }
  }
  h1 {
    text-transform: uppercase;
    font-size: 1.9em;
  }
  p {
    margin-top: 30px;
    font-size: 1.1em;
  }
}

@media screen and (max-width: 850px) {
  #puppies .content {
    padding: 40px 20px;
    h1 {
      font-size: 1.5em;
    }
    .images {
      flex-direction: column;
      img {
        margin-right: 0;
        width: 100%;
        max-width: 500px;
        max-height: 240px;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  #puppies {
    font-size: 0.9em;
    .first {
      flex-direction: column;
      text-align: center;
      img {
        margin-right: 0;
      }
      .btns {
        .btn-row {
          justify-content: center;
        }
      }
      img {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #puppies {
    .first {
      .btns {
        .btn-row {
          flex-direction: column;
          align-items: center;
          button {
            margin-right: 0;
          }
        }
      }
    }
  }
}
