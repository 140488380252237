#navbar {
  height: 80px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 30px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
  img {
    width: 120px;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    &:hover {
      cursor: pointer;
    }
  }
  &.with-bg {
    z-index: 100;
    -webkit-box-shadow: 10px 0px 25px 0px rgba(5, 4, 4, 0.5);
    -moz-box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.7);
    img {
      opacity: 1;
    }
  }
  .hamburger {
    background-color: transparent;
    border: none;
    color: white;
    width: 50px;
    height: 50px;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    display: none;
    outline: none;
    .icon {
      height: 25px;
      width: 25px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .desktop-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    color: white;
    li {
      text-decoration: none;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      box-sizing: border-box;
      opacity: 0.6;
      height: 60%;
      font-family: "Oswald", sans-serif;
      border-radius: 5px;
      &.active {
        opacity: 1;
        font-weight: bold;
      }
      &:hover {
        cursor: pointer;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: height 300ms ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  ul {
    margin-top: 80px;
    width: 100%;
    color: white;
    font-family: "Oswald", sans-serif;
    li {
      height: 60px;
      display: flex;
      font-size: 1.1em;
      align-items: center;
      justify-content: center;
      width: 100%;
      opacity: .8;
      &.active {
        opacity: 1;
        font-weight: bold;
      }
    &:hover {
        cursor: pointer !important;
        opacity: .5;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  #navbar {
    .desktop-list {
      display: none;
    }
    .hamburger {
      display: flex;
    }
  }
}
