#puppy-info {
  background-color: #383838;
  width: 100%;
  .content {
    max-width: 1200px;
    position: relative;
    color: white;
    font-family: "Oswald", sans-serif;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    margin: auto;
  }
  strong {
    font-size: 1.3em;
    font-weight: normal;
  }
  .header {
    position: relative;
    img {
      position: absolute;
      left: -15px;
      top: -10px;
    }
  }
  h1 {
    text-transform: uppercase;
    font-size: 1.9em;
  }
  h2 {
    margin-top: 30px;
  }
  p {
    margin-top: 30px;
    font-size: 1.1em;
  }
}

@media screen and (max-width: 850px) {
  #puppy-info .content {
    font-size: 0.9em;
    padding: 40px 20px;
    .images {
      flex-direction: column;
      img {
        margin-right: 0;
        width: 100%;
        max-width: 500px;
        max-height: 240px;
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #puppies {
    .first {
      flex-direction: column;
      text-align: center;
      .btns {
        .btn-row {
          justify-content: center;
        }
      }
      img {
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #puppies {
    .first {
      .btns {
        .btn-row {
          flex-direction: column;
          align-items: center;
          button {
            margin-right: 0;
          }
        }
      }
    }
  }
}
