#contact-us {
    background-color: #383838;
    width: 100%;
    .content {
      color: white;
      margin: auto;
      font-family: "Oswald", sans-serif;
      padding: 40px 60px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      align-items: center;
  
      max-width: 1200px;
    }
    strong {
      font-size: 1.3em;
      font-weight: normal;
    }
    .header {
      position: relative;
      img {
        position: absolute;
        left: -15px;
        top: -10px;
      }
    }
    .first {
      margin-top: 30px;
      padding: 40px;
      max-width: 100%;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      display: flex;
      align-items: center;
      text-align: left;
      .texts {
          display: flex;
          flex-direction: column;
          > a, > div {
              display: flex;
              align-items: center;
              margin-top: 10px;
              .icon {
                width: 25px;
                height: 25px;
                color: white;
                margin-right: 10px;
              }
          }
      }
      .mobile-img {
        display: none;
      }
      .desktop-img {
        display: inline-block;
      }
      img {
        height: auto;
        width: 250px;
        flex-shrink: 0;
        border-radius: 5px;
        margin-right: 40px;
      }
    }
    .text-block {
      display: flex;
      align-items: center;
      margin-top: 40px;
      p {
        margin: 0;
      }
      img {
        width: 250px;
        height: auto;
        flex-shrink: 0;
        border-radius: 5px;
      }
      &.right {
        text-align: right;
        img {
          margin-left: 30px;
        }
      }
      &.left {
        text-align: left;
        img {
          margin-right: 30px;
        }
      }
    }
    .images {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      margin-top: 45px;
      max-width: 900px;
      margin-bottom: 15px;
      img {
        border-radius: 5px;
        flex: 1;
        height: 200px;
        object-fit: cover;
        overflow: hidden;
        margin-right: 60px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    h1 {
      text-transform: uppercase;
      font-size: 1.9em;
    }
    p {
      margin-top: 30px;
      font-size: 1.1em;
    }
  }
  
  @media screen and (max-width: 850px) {
    #contact-us .content {
      padding: 40px 20px;
      font-size: .9em;
      .first {
        flex-direction: column;
        .desktop-img {
          display: none;
        }
        .mobile-img {
          display: inline-block;
          margin-top: 25px;
          margin-bottom: 20px;
        }
        img {
          margin-right: 0;
        }
        .texts {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
  