#banner {
  height: 520px;
  max-height: 50vh;
  width: 100%;
  background: url("./assets/banner_bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 0px 25px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  img {
      width: 350px;
      max-width: 60%;
      
  }
}