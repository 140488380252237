#whats-new {
  color: white;
  font-family: "Oswald", sans-serif;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  background-color: #383838;
  width: 100%;
  strong {
    font-size: 1.3em;
    font-weight: normal;
  }
  .header {
    position: relative;
    img {
      position: absolute;
      left: -15px;
      top: -10px;
    }
    h1 {
      text-transform: uppercase;
      font-size: 1.9em;
    }
  }
  .posts {
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
    .post {
      width: 100%;
      display: flex;
      align-items: flex-start;
      text-align: left;
      position: relative;
      padding: 50px 0;
      overflow: hidden;
      ul {
        margin-left: 40px;
      }
      .desktop-img {
        display: flex;
      }
      .mobile-img {
        display: none;
      }
      hr {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          opacity: .2;
      }
      .texts {
        padding: 0 40px;
        flex: 1;
        overflow: hidden;
        box-sizing: border-box;
        h2 {
            font-size: 1.7em;
        }
        .content {
          overflow: hidden;
          p {
            margin-top: 10px;
            word-wrap: break-word;
            width: 100%;
          }
        }
        p {
            font-size: 1.2em;
            opacity: 0.8;
        }
      }
      img {
        height: auto;
        width: 220px;
        flex-shrink: 0;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  #whats-new {
    padding: 40px 0px;
    font-size: .9em;
    
    .posts {
      .post {
        flex-direction: column;
        align-items: center;
        text-align: center;
          .texts {
            padding: 0 40px !important;
            width: 100% !important;
          }
        ul {
          margin-left: 0;
        }
        .desktop-img {
          display: none;
        }
        .mobile-img {
          display: inline-block;
          margin: 25px 0 10px 0;
        }
      }
    }
  }
}
