#footer {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    background-color: #232323;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: "Oswald", sans-serif;
    padding: 0 30px;
    .some {
        * {
            margin-right: 15px;
            font-size: 1.4em;
            max-height: 20px;
            &:hover {
                    opacity: .7;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    #footer {
        font-size: 0.8em;
    }
  }