.dogs {
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dog {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    padding: 30px 0;
    .mob-title {
      display: none;
    }
    .text {
      display: flex;
      flex-direction: column;
      button {
        width: fit-content;
        padding: 10px 20px;
        font-family: "Oswald", sans-serif;
        text-transform: uppercase;
        border: none;
        border-radius: 5px;
        font-size: 0.8em;
        color: white;
        background-color: #baa095;
        margin-top: 10px;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
      padding: 0 40px;
      flex: 1;
      h2 {
        font-size: 1.7em;
        margin-bottom: 5px;
      }
      p {
        font-size: 1.3em;
        opacity: 0.8;
      }
    }
    img {
      height: auto;
      width: 300px;
      max-width: 90%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1000px) {
  .dogs {
    .dog {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      .text {
        h2 {
          display: none;
        }
        padding: 0;
        align-items: center;
      }
      img {
        margin-bottom: 30px;
      }
      .mob-title {
        display: block;
        margin-bottom: 30px;
      }
    }
  }
}
